<template>
  <div class="page team" :loaded="Boolean(page) && Boolean(directorsPage) && Boolean(members) && Boolean(directors)">
    <section class="top-section" v-if="page">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="title">About Us</div>
            <h3>{{page.page_title}}</h3>
            <h5>{{page.subtitle}}</h5>
            <p>{{page.description}}</p>
          </div>
          <div class="section-image">
            <img :src="page.image" alt="">
          </div>
        </div>
      </div>
    </section>

    <section ref="members" class="members">
      <div class="wrapper" v-if="page && members">
        <div class="section-title">
          <h3>Our Team</h3>
          <h5>{{page.team_subtitle}}</h5>
        </div>
        <div class="container">
          <div class="member" v-for="member in members" :key="member.id">
            <div class="member-image">
              <img :src="member.image" :alt="member.name">
            </div>
            <h5>{{member.name}}</h5>
            <p>{{member.role}}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="members bod" v-if="page && directorsPage && directors">
      <div class="wrapper">
        <div class="section-title">
          <img :src="directorsPage.image" alt="">
          <h3>Board of Directors</h3>
          <h5>{{page.bod_subtitle}}</h5>
        </div>
        <div class="container">
          <div class="member" v-for="member in directors" :key="member.id">
            <div class="member-details">
              <div class="member-image">
                <img :src="member.image" :alt="member.name">
              </div>
              <h5>{{member.name}}</h5>
              <p>{{member.role}}</p>
            </div>
            <div class="member-bio" v-html="member.bio"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default {
  name: "Team",
  components: {
    Icon,
  },
  data() {
    return {
      members: null,
      directors: null,
      page: null,
      directorsPage: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
    $route() {
      this.scrollToMembers();
    },
    page() {
      this.scrollToMembers();
    },
  },
  methods: {
    scrollToMembers() {
      if (this.$route.path.includes('our-team') && this.page) {
        this.$nextTick(() => {
          this.scrollToY(this.$refs.members.offsetTop);
        });
      }
    },
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.directorsPageRef) this.directorsPageRef();
      if (this.teamRef) this.teamRef();
      if (this.directorsRef) this.directorsRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("team")
        .onSnapshot((page) => {
          this.page = page.data();
        });
      this.directorsPageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("directors")
        .onSnapshot((page) => {
          this.directorsPage = page.data();
        });
      this.teamRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}team`)
        .orderBy("order")
        .onSnapshot((members) => {
          this.members = members.docs.map((doc) => doc.data());
        });
      this.directorsRef = this.db()
        .collection(`${this.testMode ? 'test_' : ''}directors`)
        .orderBy('order')
        .onSnapshot(members => {
          this.directors = members.docs.map(doc => doc.data());
        });
    },
  },
  mounted: function () {
    this.getItems();
    this.scrollToMembers();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.directorsPageRef) this.directorsPageRef();
    if (this.teamRef) this.teamRef();
    if (this.directorsRef) this.directorsRef();
  },
};
</script>

<style scoped>
.top-section {
  position: relative;
  margin-bottom: 258px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  min-height: 530px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 96px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  font-size: 72px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  line-height: 1.2;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .section-image {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 192px;
  left: 0;
  right: 0;
  margin: 32px auto 0;
  top: 100%;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.top-section .content .title {
  display: flex;
  align-items: center;
  color: #1dacd6;
  font-size: 20px;
  margin-bottom: 24px;
}

.top-section .content .title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.top-section .section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.members:not(.bod):after {
  content: '';
  display: block;
  width: 7px;
  height: 192px;
  margin: 48px auto;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.members:after {
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 64px;
}

.section-title img {
  display: block;
  width: 100%;
  margin: 64px 0;
  border-radius: 16px;
}

.section-title h3 {
  color: #1DACD6;
  margin-bottom: 24px;
}

.section-title h5 {
  color: #656662;
}

.members .container {
  display: flex;
  flex-wrap: wrap;
}

.members .member {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.333333% - 8px);
  margin: 0 4px 64px;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  padding: 64px 48px 48px;
}

.members.bod .member {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 152px;
}

.members.bod .member .member-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 96px;
  flex-shrink: 0;
}

.members .member .member-image {
  position: relative;
  width: 192px;
  height: 192px;
  margin-bottom: 48px;
}

.members .member .member-image img {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.members .member .member-image:before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -6%;
  background: url(../assets/images/wb_team_bubbles.svg) no-repeat top right/auto 100%;
}

@media screen and (max-width: 1280px), screen and (max-height: 760px) {
  .members .member {
    width: calc(50% - 8px);
    margin-bottom: 48px;
  }
  .members.bod .member {
    padding: 64px 48px 48px 0;
  }
}

@media screen and (max-width: 1280px) {
  .top-section .container .content {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .top-section .section-image {
    display: none;
  }
  .top-section .container {
    min-height: auto;
    margin-right: 0;
  }
  .top-section .container .content {
    width: 100%;
    min-width: 0;
  }
}

@media screen and (max-width: 800px) {
  .top-section .container .content {
    margin: 0;
    padding: 48px;
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  .members.bod .member {
    flex-direction: column;
    align-items: center;
    padding: 64px 24px;
  }
  .members.bod .member .member-details {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  .members .member {
    width: 100%;
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 480px) {
  .top-section .container .content {
    padding: 32px;
  }
}
</style>
